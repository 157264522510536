<template>
  <div
      @click="close"
      :style="{display: visible ? 'flex' : 'none'}" class="video-wrap">
    <svg @click="close"
        t="1617268399295" class="icon close-x" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1331" width="200" height="200"><path d="M720.32 768a45.76 45.76 0 0 1-33.408-14.336l-416.64-416.576c-19.072-19.072-19.072-49.28 0-66.752 19.136-19.136 49.28-19.136 66.816 0l416.64 414.976c19.072 19.072 19.072 49.28 0 66.816a44.288 44.288 0 0 1-33.408 15.872z" fill="#172B4D" p-id="1332"></path><path d="M303.68 768a45.76 45.76 0 0 1-33.344-14.336c-19.136-19.072-19.136-49.28 0-66.752l414.976-416.64c19.072-19.072 49.28-19.072 66.816 0 19.072 19.136 19.072 49.28 0 66.816l-415.04 416.64a45.76 45.76 0 0 1-33.408 14.272z" fill="#172B4D" p-id="1333"></path></svg>
    <video ref="video" @click.stop="() => {return false}" controls :src="videoUrl" autoplay />
  </div>
</template>

<script>
export default {
  name: "index",
  data () {
    return {
      visible: false,
      videoUrl: ''
    }
  },
  methods: {
    play (url) {
      this.$data.videoUrl = url;
      this.$data.visible = true;
    },
    close () {
      this.$data.visible = false;
      this.$data.videoUrl = null;
      this.$refs['video'].pause();
    }
  },
  destroyed() {
    document.onclick = null
  }
}
</script>

<style scoped>
@import "./my-video.css";
@import "./media.css";
</style>